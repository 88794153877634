import axios from 'axios';
import { SERVER_URL } from '../config';

export const signin = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/admin/signIn`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const signup = async (data) => {  
	try {
		const response = await axios.post(`${SERVER_URL}/api/admin/signUp`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const updateInfo = async (data) => {  
	try {
		const response = await axios.post(`${SERVER_URL}/api/admin/updateInfo`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const changePassword = async (data) => {  
	try {
		const response = await axios.post(`${SERVER_URL}/api/admin/changePassword`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const resetPassword = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/admin/resetPassword`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const forgotPassword = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/admin/forgotPassword`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getAllAdminUsers = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/admin/getUsers`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const deleteAdminUser = async (data) => {  
	try {
		const response = await axios.post(`${SERVER_URL}/api/admin/deleteUserById`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getPermission = async (data) => {  
	try {
		const response = await axios.post(`${SERVER_URL}/api/admin/getPermission`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getAdminUserById = async (data) => {  
	try {
		const response = await axios.post(`${SERVER_URL}/api/admin/getAdminById`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const setRole = (role) => ({
	type: 'SET_ROLE',
	payload: role,
});