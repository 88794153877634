import React, { Fragment, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import {
	addVideo,
	getVideos,
    getVideoById,
    updateVideo,
    deleteVideo
} from '../../actions';

const TrainingVideo = () => {
    const [data, setData] = React.useState([]);
	const [open, setOpen] = useState(false);
	const [name, setName] = React.useState("");
	const [nameError, setNameError] = React.useState(false);
    const [videoUrl, setVideoUrl] = React.useState("");
	const [isEdit, setIsEdit] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(0);

    const editVideoModal = (id) => {
        getVideoById(id).then(res => {
            if(res.success) {
                setIsEdit(true);
                setOpen(true);
                setName(res.video.name);
                setVideoUrl(res.video.videourl);
                setSelectedId(id);
            }
        });
    }

	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setName("");
        setVideoUrl("");
		setOpen(false);
		setNameError(false);
        setIsEdit(false);
	};

	const changeName = (e) => {
		setName(e.target.value);
	};

    const changeVideoUrl = (e) => {
        setVideoUrl(e.target.value);
    }

    const removeVideo = (id) => {
        deleteVideo({id}).then(res => {
            if(res.success) {
                let temp = [];
                res.videos.forEach(video => {
                    temp.push({
                        id: video.id,
                        name: video.name,
                        video: <a href={`${video.videourl}`} target="_blank">view</a>,
                        options: (
                            <div style={{ display: "flex", justifyContent: "center"}}>
                                <i
                                    className="fa fa-pencil-square-o"
                                    style={{
                                        width: 35,
                                        fontSize: 20,
                                        padding: 11,
                                        color: "#e4566e",
                                    }}
                                    onClick={(e) => editVideoModal(video.id)}
                                ></i>
                                <UncontrolledDropdown style={{position: "unset"}} direction="down">
                                    <DropdownToggle tag="span" style={{ cursor: "pointer" }}>
                                    <i
                                        className="fa fa-ellipsis-v"
                                        style={{
                                        width: 35,
                                        fontSize: 20,
                                        padding: 11,
                                        color: "#e4566e",
                                        }}
                                    ></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => removeVideo(video.id)}>Delete</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        ),
                    });
                });
                setData(temp);
                toast.success("Successfully Deleted!");
            }
        });
    }

	const onSave = () => {
		if(name === "") {
			setNameError(true);
			return;
		}

        if(isEdit) {
            const dataT = {
                id: selectedId,
                name: name,
                videourl: videoUrl
            };
            updateVideo(dataT).then(res => {
                if(res.success) {
                    toast.success("Successfully Updated!");
                    let temp = [...data];
                    temp = temp.map(video => {
                        if (video.id === selectedId) {
                            return {
                                ...video,
                                name: name,
                                video: <a href={`${videoUrl}`} target="_blank">view</a>,
                            };
                        } else {
                            return video;
                        }
                    });
                    setData(temp);
                    onCloseModal();
                } 
            });
        } else {
            const dataT = {
                name: name,
                videourl: videoUrl
            };
            addVideo(dataT).then(res => {
                let temp = [...data];
                temp.push({
                    id: res.id,
                    name: name,
                    video: <a href={`${videoUrl}`} target="_blank">view</a>,
                    options: (
                        <div style={{ display: "flex", justifyContent: "center"}}>
                            <i
                                className="fa fa-pencil-square-o"
                                style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                }}
                                onClick={(e) => editVideoModal(res.id)}
                            ></i>
                            <UncontrolledDropdown style={{position: "unset"}} direction="down">
                                <DropdownToggle tag="span" style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-ellipsis-v"
                                    style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                    }}
                                ></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => removeVideo(res.id)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    ),
                });
                setData(temp);
                toast.success("Successfully Added!");
                onCloseModal();
            });
        }
	};

	React.useEffect(() => {
		getVideos().then(res => {
			let temp = [];
			res.videos.forEach(video => {
				temp.push({
					id: video.id,
					name: video.name,
					video: <a href={`${video.videourl}`} target="_blank">view</a>,
                    options: (
                        <div style={{ display: "flex", justifyContent: "center"}}>
                            <i
                                className="fa fa-pencil-square-o"
                                style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                }}
                                onClick={(e) => editVideoModal(video.id)}
                            ></i>
                            <UncontrolledDropdown style={{position: "unset"}} direction="down">
                                <DropdownToggle tag="span" style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-ellipsis-v"
                                    style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                    }}
                                ></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => removeVideo(video.id)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    ),
				});
			});
			setData(temp);
		});
	}, []);

    return (
        <Fragment>
            <Breadcrumb title="Training Videos" parent="Extras" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Training Video</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="btn-popup pull-right">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={onOpenModal}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"
                                    >
                                        Add video
                                    </Button>
                                    <Modal isOpen={open} toggle={onCloseModal}>
                                        <ModalHeader toggle={onCloseModal}>
                                            <h5
                                                className="modal-title f-w-600"
                                                id="exampleModalLabel2"
                                            >
                                                { isEdit ? "Edit video" : "Add video" }
                                            </h5>
                                        </ModalHeader>
                                        <ModalBody>
                                            <Form>
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="recipient-name"
                                                        className="col-form-label"
                                                    >
                                                        Name :
                                                    </Label>
                                                    <Input type="text" className={nameError ? 'form-control input-error' : 'form-control'} value={name}
                                                        onChange={changeName} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="message-text"
                                                        className="col-form-label"
                                                    >
                                                        Video Url :
                                                    </Label>
                                                    <Input type="text" className={nameError ? 'form-control input-error' : 'form-control'} value={videoUrl}
                                                        onChange={changeVideoUrl} />
                                                </FormGroup>
                                            </Form>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                type="button"
                                                color="primary"
                                                onClick={onSave}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                type="button"
                                                color="secondary"
                                                onClick={onCloseModal}
                                            >
                                                Close
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    <Datatable
                                        multiSelectOption={false}
                                        multiSelectOptionShow={false}
                                        myData={data}
                                        pageSize={5}
                                        pagination={true}
                                        class="-striped -highlight"
                                        productTableType={3}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default TrainingVideo;