import React, { Fragment, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row, Input } from "reactstrap";
import { trackOrders } from "../../actions";
import logo from "../../assets/images/dashboard/logo.png";

const Orders = () => {
	const [searchTerm, setSearchTerm] = React.useState('');
	const [orderData, setOrderData] = React.useState([]);
	const [filteredData, setFilteredData] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(true);

	const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);

        const filtered = orderData.filter(row => {
            return Object.values(row).some(
                cell => String(cell).toLowerCase().includes(value.toLowerCase())
            );
        });
        setFilteredData(filtered);
    };

	const convertFormatString = (price) => {
		return price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
	};

	useEffect(() => {
		trackOrders().then(res => {
			if(res.success) {
				let temp = [];
                res.order_data.forEach((item) => {
					let status_span = "";
					if(item.status === "Pending") {
						status_span = <span className="badge badge-danger">Pending</span>;
					} else if(item.status === "Waiting Client Approval") {
						status_span = <span className="badge badge-warning">Waiting Client Approval</span>;
					} else if(item.status === "Processing") {
						status_span = <span className="badge badge-secondary">Processing</span>;
					} else if(item.status === "Paid") {
						status_span = <span className="badge badge-success">Paid</span>;
					}

                    temp.push({
						client: item.user,
                        "Order Id": item.order_number,
                        "PO Number": item.po_number,
                        amount: convertFormatString(item.amount),
                        date: item.date,
                        "Due Date": item.due_date,
                        status: status_span
                    });
                });

                setOrderData(temp);
				setFilteredData(temp);
				setIsLoading(false);
			}
		});
	}, []);

	return (
		<>
		{
			isLoading ?
				<div className="loader-wrapper">
					<div className="loader-ring">
						<img src={logo} alt="logo" />
					<span className="loader-span"></span>
					</div>
				</div>
			:
				<Fragment>
					<Breadcrumb title="List" parent="Orders" />

					<Container fluid={true}>
						<Row>
							<Col sm="12">
								<Card>
									<CardHeader style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
										<h5>Track Orders</h5>
										<Input 
											type="text" 
											className="form-control" 
											placeholder="Search"
											value={searchTerm}
											onChange={handleSearch}
											style={{
												width: "30%",
												maxWidth: "200px"
											}}
										/>
									</CardHeader>
									<CardBody className="order-datatable">
										<Datatable
											multiSelectOption={false}
											multiSelectOptionShow={false}
											myData={filteredData}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
										/>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</Fragment>
		}
		</>
	);
};

export default Orders;
