import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, Container, Row, Col, Label, FormGroup, Input, Button, Spinner } from "reactstrap";
import { getQBSetting, setQBSetting, testQBConnection } from '../../actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QuickBooksSetting = () => {
    const [clientId, setClientId] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [redirectUri, setRedirectUri] = useState("");
    const [realmId, setRealmId] = useState("");
    const [refreshToken, setRefreshToken] = useState("");
    const [testSuccess, setTestSuccess] = useState(false);
    const [isTesting, setIsTesting] = useState(false);

    const handleClientId = (e) => {
        setClientId(e.target.value);
    }

    const handleClientSecret = (e) => {
        setClientSecret(e.target.value);
    }

    const handleRedirectUri = (e) => {
        setRedirectUri(e.target.value);
    }

    const handleRealmId = (e) => {
        setRealmId(e.target.value);
    }

    const handleRefreshToken = (e) => {
        setRefreshToken(e.target.value);
    }

    const handleTestConnection = () => {
        setIsTesting(true);
        const data = {
            clientId,
            clientSecret,
            redirectUri,
            realmId,
            refresh_token: refreshToken
        };
        testQBConnection(data).then((res) => {
            if(res.success) {
                notify("QuickBooks is connected successfully.", true);
                setTestSuccess(true);
            } else {
                notify("QuickBooks is failed.", false);
                setTestSuccess(false);
            }
            setIsTesting(false);
        }).catch(err => {
            notify("Sever is failed.", false);
            setIsTesting(false);
        });
    }

    const handleUpdate = () => {
        if(!testSuccess) {
            notify("Please test QuickBooks connection first!");
            return;
        }
        if(clientId === "") {
            notify("Please input Client ID.");
            return;
        }
        if(clientSecret === "") {
            notify("Please input Client Secret.");
            return;
        }
        if(redirectUri === "") {
            notify("Please input Redirect Url.");
            return;
        }
        if(realmId === "") {
            notify("Please input Realm ID.");
            return;
        }
        if(refreshToken === "") {
            notify("Please input Refresh Token.");
            return;
        }

        const data = {
            clientId,
            clientSecret,
            redirectUri,
            realmId,
            refresh_token: refreshToken
        };
        setQBSetting(data).then((res) => {
            notify("Saved Successfully!", true);
        }).catch(err => {
            console.log(err)
        });
    }

    const notify = (text, success) => {
		const options = {
			position: "top-right",
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		};
		if (success) {
			toast.success(text, options);
		} else {
			toast.warn(text, options);
		}
	};

    useEffect(() => {
        getQBSetting().then(res => {
            if(res.success) {
                setClientId(res.data.clientId);
                setClientSecret(res.data.clientSecret);
                setRedirectUri(res.data.redirectUri);
                setRealmId(res.data.realmId);
                setRefreshToken(res.data.refresh_token);
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])

    return (
        <Fragment>
            <Breadcrumb title="Twilio Settings" parent="Settings" />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col sm="12">
                                <div className="btn-popup">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={handleTestConnection}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"
                                    >
                                        {
                                            isTesting &&
                                            <Spinner size="sm" style={{marginRight: "0.5rem"}}>
                                                Loading...
                                            </Spinner>
                                        }
                                        Test Connection
                                    </Button>
                                </div>
                                <FormGroup>
                                    <Label className="col-form-label pt-0 color-red">
                                        <span>*</span> Client ID
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        required=""
                                        placeholder="Client ID"
                                        name="cid"
                                        value={clientId}
                                        onChange={handleClientId}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="col-form-label pt-0 color-red">
                                        <span>*</span> Client Secret
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        required=""
                                        placeholder="Client Secret"
                                        name="secret"
                                        value={clientSecret}
                                        onChange={handleClientSecret}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="col-form-label pt-0 color-red">
                                        <span>*</span> Redirect Uri
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        required=""
                                        placeholder="Redirect Uri"
                                        name="uri"
                                        value={redirectUri}
                                        onChange={handleRedirectUri}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="col-form-label pt-0 color-red">
                                        <span>*</span> Realm ID
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        required=""
                                        placeholder="Realm ID"
                                        name="realm"
                                        value={realmId}
                                        onChange={handleRealmId}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="col-form-label pt-0 color-red">
                                        <span>*</span> Refresh Token
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        required=""
                                        placeholder="Refresh Token"
                                        name="token"
                                        value={refreshToken}
                                        onChange={handleRefreshToken}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="btn-popup pull-right" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '3rem' }}>
                            <div className="btn btn-secondary" onClick={handleUpdate}>
                                Save
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Container>
            <ToastContainer
				position="top-right"
				autoClose={4000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<ToastContainer />
        </Fragment>
    );
};

export default QuickBooksSetting;
