import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Button, Form, FormGroup, Input, Label, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import Breadcrumb from "../common/breadcrumb";
import 'react-toastify/dist/ReactToastify.css';
import { updateUser, getUser } from "../../actions";
import { SERVER_URL } from "../../config";
import { countries } from "countries-list";

const EditUser = () => {
	const history = useNavigate();
    const params = useParams();
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [step, setStep] = React.useState(0);
	const [image, setImage] = React.useState(null);
	const [imagedata, setImagedata] = React.useState("");
	const [company, setCompany] = React.useState("");
	const [displayName, setDisplayName] = React.useState("");
	const [phoneNumber, setPhoneNumber] = React.useState("");
	const [mobileNumber, setMobileNumber] = React.useState("");
	const [fax, setFax] = React.useState("");
	const [other, setOther] = React.useState("");
	const [website, setWebsite] = React.useState("");
	const [printChecks, setPrintChecks] = React.useState("");
	const [streetAddress, setStreetAddress] = React.useState("");
    const [streetAddress2, setStreetAddress2] = React.useState("");
	const [city, setCity] = React.useState("");
	const [state, setState] = React.useState("");
	const [zipCode, setZipCode] = React.useState("");
	const [country, setCountry] = React.useState("");
    const [imageChange, setImageChange] = React.useState(false);
    const [oldPhoto, setOldPhoto] = React.useState("");
    const [shipping_streetAddress, setShippingStreetAddress] = React.useState("");
	const [shipping_streetAddress2, setShippingStreetAddress2] = React.useState("");
	const [shipping_city, setShippingCity] = React.useState("");
	const [shipping_state, setShippingState] = React.useState("");
	const [shipping_zipCode, setShippingZipCode] = React.useState("");
	const [shipping_country, setShippingCountry] = React.useState("");
	const [countryCodes, setCountryCodes] = React.useState([]);
    const [countryNames, setCountryNames] = React.useState("");

	const changeFirstName = (e) => {
		setFirstName(e.target.value);
	};

	const changeLastName = (e) => {
		setLastName(e.target.value);
	};

	const changeCompanyName = (e) => {
		setCompany(e.target.value);
	};

	const changeDisplayName = (e) => {
		setDisplayName(e.target.value);
	};

	const changeEmail = (e) => {
		setEmail(e.target.value);
	};

	const changePhoneNumber = (e) => {
		setPhoneNumber(e.target.value);
	};

	const changeMobileNumber = (e) => {
		setMobileNumber(e.target.value);
	};

	const changeFax = (e) => {
		setFax(e.target.value);
	};

	const changeOther = (e) => {
		setOther(e.target.value);
	};

	const changeWebsite = (e) => {
		setWebsite(e.target.value);
	};

	const changePrintChecks = (e) => {
		setPrintChecks(e.target.value);
	};

	const changeStreetAddress = (e) => {
		setStreetAddress(e.target.value);
	};

    const changeStreetAddress2 = (e) => {
		setStreetAddress2(e.target.value);
	};

	const changeCity = (e) => {
		setCity(e.target.value);
	};

	const changeState = (e) => {
		setState(e.target.value);
	};

	const changeZipCode = (e) => {
		setZipCode(e.target.value);
	};

	const changeCountry = (e) => {
		setCountry(e.target.value);
	};

    const changeShippingStreetAddress = (e) => {
		setShippingStreetAddress(e.target.value);
	};

	const changeShippingStreetAddress2 = (e) => {
		setShippingStreetAddress2(e.target.value);
	};

	const changeShippingCity = (e) => {
		setShippingCity(e.target.value);
	};

	const changeShippingState = (e) => {
		setShippingState(e.target.value);
	};

	const changeShippingZipCode = (e) => {
		setShippingZipCode(e.target.value);
	};

	const changeShippingCountry = (e) => {
		setShippingCountry(e.target.value);
	};

	const notify = (text, success) => {
		const options = {
			position: "top-right",
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		};
		if (success) {
			toast.success(text, options);
		} else {
			toast.warn(text, options);
		}
	};

	const save = () => {
		let text = "";
		if (firstName === "" || lastName === "" || email === "" || displayName === "") {
			if (firstName === "") {
				text += "First Name";
			}
			if (lastName === "") {
				if (text === "") {
					text += "Last Name";
				} else {
					text += ", Last Name";
				}
			}
			if (displayName === "") {
				if (text === "") {
					text += "Customer Display Name";
				} else {
					text += ", Customer Display Name";
				}
			}
			if (email === "") {
				if (text === "") {
					text += "Email";
				} else {
					text += ", Email";
				}
			}

			notify('Please input ' + text + '.', false);
			return;
		}

		const data = {
            user_id: params.id,
			first_name: firstName,
			last_name: lastName,
			company: company,
			display_name: displayName,
			phone_number: phoneNumber,
			mobile_number: mobileNumber,
			fax: fax,
			other: other,
			website: website,
			print_checks: printChecks,
			email: email,
			imagedata: imagedata || "no image",
			address1: streetAddress,
			address2: streetAddress2,
			city: city,
			state: state,
			postcode: zipCode,
			country: country,
            imageChange: imageChange,
            oldPhoto: oldPhoto,
            shipping_address1: shipping_streetAddress,
			shipping_address2: shipping_streetAddress2,
			shipping_city: shipping_city,
			shipping_state: shipping_state,
			shipping_postcode: shipping_zipCode,
			shipping_country: shipping_country,
		};
		updateUser(data).then(res => {
			if (res.success) {
				history(`${process.env.PUBLIC_URL}/users/list-user`);
				notify("User updated successfully", true);
			} else {
				notify(res.msg, false);
			}
		});
	};

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result);
			}
			fileReader.onerror = (error) => {
				reject(error);
			}
		})
	}

	const handleImageChange = async (event) => {
		const selectedImage = event.target.files[0];
		setImage(selectedImage);
		const base64 = await convertBase64(event.target.files[0]);
		setImagedata(base64);
        setImageChange(true);
	}

    useEffect(() => {
        const countryCodes = Object.keys(countries);
        const countryNames = countryCodes.map(code => countries[code].name);
		setCountryCodes(countryCodes);
        setCountryNames(countryNames);

        if (params?.id) {
            const data = {
                user_id: params.id
            }
            getUser(data).then(res => {
                if (res.status === "success" && res.data) {
                    const userData = res.data;
    
                    setImage(userData.User_Address.photo);
                    setOldPhoto(userData.User_Address.photo);
                    setFirstName(userData.first_name || "");
                    setLastName(userData.last_name || "");
                    setCompany(userData.User_Address.company || "");
                    setDisplayName(userData.display_name || "");
                    setEmail(userData.email || "");
                    setPhoneNumber(userData.phone_number || "");
                    setMobileNumber(userData.mobile_number || "");
                    setFax(userData.fax || "");
                    setOther(userData.other || "");
                    setWebsite(userData.website || "");
                    setPrintChecks(userData.print_checks || "");
                    setStreetAddress(userData.User_Address.address_first || "");
                    setStreetAddress2(userData.User_Address.address_second || "");
                    setCity(userData.User_Address.city || "");
                    setState(userData.User_Address.state || "");
                    setZipCode(userData.User_Address.postcode || "");
                    setCountry(userData.User_Address.country || "");
                    setShippingStreetAddress(userData.User_Address.shipping_address_first || "");
                    setShippingStreetAddress2(userData.User_Address.shipping_address_second || "");
                    setShippingCity(userData.User_Address.shipping_city || "");
                    setShippingState(userData.User_Address.shipping_state || "");
                    setShippingZipCode(userData.User_Address.shipping_postcode || "");
                    setShippingCountry(userData.User_Address.shipping_country || "");
                }
            })
		}
    }, [])
    
	return (
		<Fragment>
            <Breadcrumb title="Edit Customer" parent="Customers" />
            <Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5> Edit Customer</h5>
                                <div className="pull-right">
                                    <Button type="button" color="primary" onClick={save}>
                                        SAVE
                                    </Button>
                                </div>
							</CardHeader>
							<CardBody>
                                <Tabs selectedIndex={step} onSelect={(index) => setStep(index)}>
                                    <TabList className="nav nav-tabs tab-coupon">
                                        <Tab className="nav-link">Account</Tab>
                                        <Tab className="nav-link">Billing Address</Tab>
                                        <Tab className="nav-link">Shipping Address</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <Fragment>
                                            <Form className="needs-validation user-add" noValidate="">
                                                <h4>Account Details</h4>
                                                <FormGroup className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <input
                                                        type="file"
                                                        id="imageUpload"
                                                        onChange={handleImageChange}
                                                        hidden
                                                    />
                                                    <label
                                                        htmlFor="imageUpload"
                                                        style={{
                                                            border: '1px solid black',
                                                            width: 200,
                                                            height: 200,
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        {
                                                            image ? <img src={imageChange || image === "" ? URL.createObjectURL(image) : `${SERVER_URL}\\images\\photo\\${image}`} alt="Uploaded Image" style={{ width: '200px', borderRadius: '50%' }} /> : 
                                                            <span>
                                                                Upload Image
                                                            </span>
                                                        }
                                                    </label>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        First Name <span>*</span>
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={firstName}
                                                            onChange={changeFirstName}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Last Name <span>*</span> 
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={lastName}
                                                            onChange={changeLastName}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Company Name
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={company}
                                                            onChange={changeCompanyName}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Customer Display Name <span>*</span> 
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={displayName}
                                                            onChange={changeDisplayName}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Email <span>*</span> 
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="email"
                                                            required=""
                                                            value={email}
                                                            onChange={changeEmail}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Phone Number
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={phoneNumber}
                                                            onChange={changePhoneNumber}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Mobile Number
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={mobileNumber}
                                                            onChange={changeMobileNumber}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Fax
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={fax}
                                                            onChange={changeFax}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Other
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={other}
                                                            onChange={changeOther}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Website
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={website}
                                                            onChange={changeWebsite}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Name to print on checks
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={printChecks}
                                                            onChange={changePrintChecks}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Form>
                                            <div className="pull-right">
                                                <Button type="button" color="primary" onClick={() => {
                                                    setStep(1)
                                                }}>
                                                    NEXT
                                                </Button>
                                            </div>
                                        </Fragment>
                                    </TabPanel>
                                    <TabPanel>
                                        <Fragment>
                                            <Form>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Street Address1
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={streetAddress}
                                                            onChange={changeStreetAddress}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Street Address2
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={streetAddress2}
                                                            onChange={changeStreetAddress2}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        City
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={city}
                                                            onChange={changeCity}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        State
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={state}
                                                            onChange={changeState}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        ZIP code
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={zipCode}
                                                            onChange={changeZipCode}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Country
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <select className="form-select py-2" size="1" value={country} onChange={changeCountry}>
                                                            {
                                                                countryCodes.map((item, index) => {
                                                                    return <option value={item} key={index}>{countryNames[index]}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </FormGroup>
                                            </Form>
                                            <div className="pull-right">
                                                <Button type="button" color="primary" onClick={() => {
                                                    setStep(2)
                                                }}>
                                                    NEXT
                                                </Button>
                                            </div>
                                        </Fragment>
                                    </TabPanel>
                                    <TabPanel>
                                        <Fragment>
                                            <Form>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Street Address1
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={shipping_streetAddress}
                                                            onChange={changeShippingStreetAddress}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Street Address2
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={shipping_streetAddress2}
                                                            onChange={changeShippingStreetAddress2}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        City
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={shipping_city}
                                                            onChange={changeShippingCity}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        State
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={shipping_state}
                                                            onChange={changeShippingState}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        ZIP code
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            required=""
                                                            value={shipping_zipCode}
                                                            onChange={changeShippingZipCode}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-xl-3 col-md-4">
                                                        Country
                                                    </Label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <select className="form-select py-2" size="1" value={shipping_country} onChange={changeShippingCountry}>
                                                            {
                                                                countryCodes.map((item, index) => {
                                                                    return <option value={item} key={index}>{countryNames[index]}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </FormGroup>
                                            </Form>
                                            <div className="pull-right">
                                                <Button type="button" color="primary" onClick={save}>
                                                    SAVE
                                                </Button>
                                            </div>
                                        </Fragment>
                                    </TabPanel>
                                </Tabs>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<ToastContainer
				position="top-right"
				autoClose={4000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<ToastContainer />
		</Fragment>
	);
};

export default EditUser;
