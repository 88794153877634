import axios from 'axios';
import { SERVER_URL } from '../config';

export const getAllUsers = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/user/getUsers`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getUser = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/user/getUser`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const updateUser = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/user/updateUser`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const createUser = async (data) => {  
	try {
		const response = await axios.post(`${SERVER_URL}/api/user/signUP`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const deleteUser = async (data) => {  
	try {
		const response = await axios.post(`${SERVER_URL}/api/user/deleteUsers`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const UpdateEmailInfo = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/user/UpdateEmailInfo`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const LoadEmailInfo = async () => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/user/LoadEmailInfo`);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const LoadSMTPInfo = async () => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/user/LoadSMTPInfo`);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getAllLegalForms = async () => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/user/getLegalForms`);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getAllLegalFormUsers = async () => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/user/getLegalFormUsers`);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const generateRandomBytes = async (length) => {
    try {
        const buffer = await window.crypto.getRandomValues(new Uint8Array(length));
        const hexString = Array.from(buffer).map(byte => byte.toString(16).padStart(2, '0')).join('');
        return hexString;
    } catch (error) {
        console.error('Error generating random bytes:', error);
        throw error;
    }
};

export const addLegalForm = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/user/addLegalForm`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const createTechnician = async (data) => {  
    try {
		const response = await axios.post(`${SERVER_URL}/api/technician/create`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const updateTechnician = async (data) => {  
    try {
		const response = await axios.post(`${SERVER_URL}/api/technician/update`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const deleteTechnician = async (data) => {  
    try {
		const response = await axios.post(`${SERVER_URL}/api/technician/delete`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getTechnicianById = async (data) => {  
    try {
		const response = await axios.post(`${SERVER_URL}/api/technician/getById`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getTechnicianList = async () => {  
    try {
		const response = await axios.post(`${SERVER_URL}/api/technician/getAll`);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getEmployerList = async () => {  
    try {
		const response = await axios.post(`${SERVER_URL}/api/technician/getAllEmployer`);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const testEmailServer = async (data) => {  
    try {
		const response = await axios.post(`${SERVER_URL}/api/user/testEmail`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const approveLegalForm = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/user/approveLegalForm`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const deleteLegalForm = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/user/deleteLegalForm`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}