import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Container, Row, Col, Label, FormGroup, Input } from "reactstrap";
import { getShippingSetting, setShippingSetting } from '../../actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ShippingSetting = () => {
    const [shippingDate, setShippingDate] = useState(5);
    const [quantityPerDay, setQuantityPerDay] = useState(5);

    const handleShippingDate = (e) => {
        if(e.target.value >= 0) {
            setShippingDate(e.target.value);
        } else {
            setShippingDate(0);
        }
    }

    const handleQuantityPerDay = (e) => {
        if(e.target.value >= 0) {
            setQuantityPerDay(e.target.value);
        } else {
            setQuantityPerDay(0);
        }
    }

    const handleUpdate = () => {
        if(shippingDate === "") {
            notify("Please input Shipping Date.")
            return;
        }
        if(quantityPerDay === "") {
            notify("Please input Quantity Per Day.")
            return;
        }
        const data = {
            shipping_date: shippingDate,
            quantity_per_day: quantityPerDay,
        };
        setShippingSetting(data).then((res) => {
            notify("Save Successfully!", true);
        }).catch(err => {
            console.log(err)
        })
    }

    const notify = (text, success) => {
		const options = {
			position: "top-right",
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		};
		if (success) {
			toast.success(text, options);
		} else {
			toast.warn(text, options);
		}
	};

    useEffect(() => {
        getShippingSetting().then(res => {
            if(res.success) {
                setShippingDate(res.data.shipping_date);
                setQuantityPerDay(res.data.quantity_per_day);
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])

    return (
        <Fragment>
            <Breadcrumb title="Shipping" parent="Settings" />
            <Container fluid={true}>
                <Card>
                    <CardHeader>
                        <h5>Settings for Shipping</h5>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Label className="col-form-label pt-0">
                                        <span>*</span> Shipping Processing Date
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="number"
                                        required=""
                                        placeholder="Shipping Date"
                                        name="date"
                                        value={shippingDate}
                                        onChange={handleShippingDate}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="col-form-label pt-0">
                                        <span>*</span> Daily Processing Order
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="number"
                                        required=""
                                        placeholder="Shipping Date"
                                        name="date"
                                        value={quantityPerDay}
                                        onChange={handleQuantityPerDay}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="btn-popup pull-right" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '3rem' }}>
                            <div className="btn btn-secondary" onClick={handleUpdate}>
                                Save
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Container>
            <ToastContainer
				position="top-right"
				autoClose={4000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<ToastContainer />
        </Fragment>
    );
};

export default ShippingSetting;
