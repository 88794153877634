import axios from 'axios';
import { SERVER_URL } from '../config';

export const trackOrders = async () => {
	try {
		let res = await axios.get(`${SERVER_URL}/api/order/trackOrders`);
		return res.data;
	} catch(err) {
		console.log(err)
	}
}