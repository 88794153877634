import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { getTechnicianList, deleteTechnician } from '../../actions';

const List_technician = () => {
	const [data, setData] = useState([]);

	const removeTechnician = (id) => {
		deleteTechnician({id}).then(res => {
			if(res.success) {
				let temp = [];
				res.technicianList.forEach((technician) => {
					temp.push({
						id: technician.id,
						f_name: technician.first_name,
						l_name: technician.last_name,
						email: technician.email,
						phone: technician.phone_number,
						employer: technician.employer,
						options : <div style={{ display: "flex", justifyContent: "center" }}>
							<Link
								to={`/users/technician/edit/${technician.id}`}
							>
								<i
									className="fa fa-pencil-square-o"
									style={{
										width: 35,
										fontSize: 20,
										padding: 11,
										color: "#e4566e",
									}}
								></i>
							</Link>
							<UncontrolledDropdown style={{position: "unset"}} direction="down">
                                <DropdownToggle tag="span" style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-ellipsis-v"
                                    style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                    }}
                                ></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => removeTechnician(technician.id)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
						</div>
					});
				});
				setData(temp);
				toast.success("Successfully Deleted!");
			}
		});
	};

	useEffect(() => {
		getTechnicianList().then(res => {
			if(res.success) {
				let temp = [];
				res.technicianList.forEach((technician) => {
					temp.push({
						id: technician.id,
						f_name: technician.first_name,
						l_name: technician.last_name,
						email: technician.email,
						phone: technician.phone_number,
						employer: technician.employer,
						options : <div style={{ display: "flex", justifyContent: "center" }}>
							<Link
								to={`/users/technician/edit/${technician.id}`}
							>
								<i
									className="fa fa-pencil-square-o"
									style={{
										width: 35,
										fontSize: 20,
										padding: 11,
										color: "#e4566e",
									}}
								></i>
							</Link>
							<UncontrolledDropdown style={{position: "unset"}} direction="down">
                                <DropdownToggle tag="span" style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-ellipsis-v"
                                    style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                    }}
                                ></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => removeTechnician(technician.id)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
						</div>
					});
				});
				setData(temp);
			}
		});
	}, []);

	return (
		<Fragment>
			<Breadcrumb title="Technician List" parent="Customers" />
			<Container fluid={true}>
				<Card>
					<CardHeader>
						<h5>Technician Details</h5>
					</CardHeader>
					<CardBody>
						<div className="btn-popup pull-right">
							<Link to="/users/technician/create" className="btn btn-secondary">
								Create Technician
							</Link>
						</div>
						<div className="clearfix"></div>
						<div
							id="batchDelete"
							className="category-table user-list order-table coupon-list-delete"
						>
							<Datatable
								multiSelectOption={false}
								multiSelectOptionShow={false}
								myData={data}
								pageSize={10}
								pagination={true}
								class="-striped -highlight"
								userTable={true}
							/>
						</div>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default List_technician;
