import React, { Fragment, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import {
	addPdf,
	getPdfs,
	updatePdf,
	getPdfById,
	deletePdf
} from '../../actions';
import { SERVER_URL } from "../../config";

const PDFs_material = () => {
	const [data, setData] = React.useState([]);
	const [open, setOpen] = useState(false);
	const [file, setFile] = React.useState("");
	const [fileUrl, setFileUrl] = useState('');
	const [oldFile, setOldFile] = useState('');
	const fileRef = React.useRef(null);
	const [name, setName] = React.useState("");
	const [nameError, setNameError] = React.useState(false);
	const [fileError, setFileError] = React.useState(false);
    const [type, setType] = React.useState(0);
	const [isEdit, setIsEdit] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(0);
	const [isPdfChange, setIsPdfChange] = React.useState(false);

	const editPdfModal = (id) => {
        getPdfById(id).then(res => {
            if(res.success) {
                setIsEdit(true);
                setOpen(true);
                setName(res.pdf.name);
                setType(res.pdf.type);
				setFileUrl(res.pdf.title);
				setOldFile(res.pdf.title);
                setSelectedId(id);
            }
        });
    }

	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setName("");
		setType(0);
		fileRef.current.value = '';
		setOpen(false);
		setIsEdit(false);
		setFileError(false);
		setNameError(false);
		setOldFile("");
		setFileUrl("");
		setIsPdfChange(false);
	};

	const changeName = (e) => {
		setName(e.target.value);
	};
	
	const changeFile = async (e) => {
		const selectedFile = e.target.files[0];

		if (selectedFile) {
			if (selectedFile.type === 'application/pdf') {
				setIsPdfChange(true);
				setFileUrl("");
				setFile(e.target.files[0]);
			} else {
				setFileError(true);
			}
		}
	};

    const changeType = async (e) => {
        setType(parseInt(e.target.value));
    };

	const removePdf = (id) => {
		deletePdf({id}).then(res => {
			if(res.success) {
				let temp = [];
				res.pdfs.forEach(pdf => {
					temp.push({
						id: pdf.id,
						name: pdf.name,
						type: pdf.type == 0? 'PDFs material' : pdf.type == 1? 'EDF' : 'Other',
						options: (
							<div style={{ display: "flex", justifyContent: "center"}}>
								<a href={`${SERVER_URL + '\\pdfs\\' + pdf.title}`} target="_blank" style={{display: "flex", alignItems: "center"}}>view</a>
								<i
									className="fa fa-pencil-square-o"
									style={{
										width: 35,
										fontSize: 20,
										padding: 11,
										color: "#e4566e",
									}}
									onClick={(e) => editPdfModal(pdf.id)}
								></i>
								<UncontrolledDropdown style={{position: "unset"}} direction="down">
									<DropdownToggle tag="span" style={{ cursor: "pointer" }}>
									<i
										className="fa fa-ellipsis-v"
										style={{
										width: 35,
										fontSize: 20,
										padding: 11,
										color: "#e4566e",
										}}
									></i>
									</DropdownToggle>
									<DropdownMenu>
										<DropdownItem onClick={() => removePdf(pdf.id)}>Delete</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							</div>
						),
					});
				});
				setData(temp);
				toast.success("Successfully Deleted!");
			}
		});
	};

	const onSave = () => {
		if(name === "") {
			setNameError(true);
			return;
		}
		if(file === "") {
			setFileError(true);
			return;
		}

		const formData = new FormData() ;
        formData.append('name', name);
        formData.append('type', type);
        formData.append('file', file);

		if(isEdit) {
			formData.append('id', selectedId);
			formData.append('isPdfChange', isPdfChange);
			formData.append('oldFile', oldFile);

			updatePdf(formData).then(res => {
				if(res.success) {
                    toast.success("Successfully Updated!");
                    let temp = [...data];
                    temp = temp.map(pdf => {
                        if (pdf.id === selectedId) {
							if(isPdfChange) {
								return {
									...pdf,
									name: name,
									type: type === 0 ? 'PDFs material' : type === 1 ? 'EDF' : 'Other',
									options: (
										<div style={{ display: "flex", justifyContent: "center"}}>
											<a href={`${SERVER_URL + '\\pdfs\\' + res.title}`} target="_blank" style={{display: "flex", alignItems: "center"}}>view</a>
											<i
												className="fa fa-pencil-square-o"
												style={{
													width: 35,
													fontSize: 20,
													padding: 11,
													color: "#e4566e",
												}}
												onClick={(e) => editPdfModal(selectedId)}
											></i>
											<UncontrolledDropdown style={{position: "unset"}} direction="down">
												<DropdownToggle tag="span" style={{ cursor: "pointer" }}>
												<i
													className="fa fa-ellipsis-v"
													style={{
													width: 35,
													fontSize: 20,
													padding: 11,
													color: "#e4566e",
													}}
												></i>
												</DropdownToggle>
												<DropdownMenu>
													<DropdownItem onClick={() => removePdf(selectedId)}>Delete</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown>
										</div>
									),
								};
							} else {
								return {
									...pdf,
									name: name,
									type: type === 0 ? 'PDFs material' : type === 1 ? 'EDF' : 'Other',
								};	
							}
                        } else {
                            return pdf;
                        }
                    });
                    setData(temp);
                    onCloseModal();
                } 
			});
		} else {
			addPdf(formData).then(res => {
				let temp = [...data];
				temp.push({
					id: res.id,
					name: name,
					type: type === 0 ? 'PDFs material' : type === 1 ? 'EDF' : 'Other',
					options: (
                        <div style={{ display: "flex", justifyContent: "center"}}>
							<a href={`${SERVER_URL + '\\pdfs\\' + res.title}`} target="_blank" style={{display: "flex", alignItems: "center"}}>view</a>
                            <i
                                className="fa fa-pencil-square-o"
                                style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                }}
                                onClick={(e) => editPdfModal(res.id)}
                            ></i>
                            <UncontrolledDropdown style={{position: "unset"}} direction="down">
                                <DropdownToggle tag="span" style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-ellipsis-v"
                                    style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                    }}
                                ></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => removePdf(res.id)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    ),
				});
				setData(temp);
				toast.success("Successfully Added!");
				onCloseModal();
			});
		}
	};

	React.useEffect(() => {
		getPdfs().then(res => {
			let temp = [];
			res.pdfs.forEach(pdf => {
				temp.push({
					id: pdf.id,
					name: pdf.name,
                    type: pdf.type === 0? 'PDFs material' : pdf.type === 1? 'EDF' : 'Other',
					options: (
                        <div style={{ display: "flex", justifyContent: "center"}}>
							<a href={`${SERVER_URL + '\\pdfs\\' + pdf.title}`} target="_blank" style={{display: "flex", alignItems: "center"}}>view</a>
                            <i
                                className="fa fa-pencil-square-o"
                                style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                }}
                                onClick={(e) => editPdfModal(pdf.id)}
                            ></i>
                            <UncontrolledDropdown style={{position: "unset"}} direction="down">
                                <DropdownToggle tag="span" style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-ellipsis-v"
                                    style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                    }}
                                ></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => removePdf(pdf.id)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    ),
				});
			});
			setData(temp);
		});
	}, []);

	return (
		<Fragment>
			<Breadcrumb title="PDFs material" parent="Products" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5>PDFs material</h5>
							</CardHeader>
							<CardBody>
								<div className="btn-popup pull-right">
									<Button
										type="button"
										color="secondary"
										onClick={onOpenModal}
										data-toggle="modal"
										data-original-title="test"
										data-target="#exampleModal"
									>
										Add pdf
									</Button>
									<Modal isOpen={open} toggle={onCloseModal}>
										<ModalHeader toggle={onCloseModal}>
											<h5
												className="modal-title f-w-600"
												id="exampleModalLabel2"
											>
												{ isEdit ? "Edit Pdf" : "Add Pdf" }
											</h5>
										</ModalHeader>
										<ModalBody>
											<Form>
												<FormGroup>
													<Label
														htmlFor="recipient-name"
														className="col-form-label"
													>
														Name :
													</Label>
													<Input type="text" className={nameError ? 'form-control input-error' : 'form-control'} value={name}
														onChange={changeName} />
												</FormGroup>
												<FormGroup>
													<Label
														htmlFor="message-text"
														className="col-form-label"
													>
														PDF File :
													</Label>
													<Input
														className={fileError ? 'form-control input-error' : 'form-control'}
														type="file"
														ref={fileRef}
														onChange={changeFile}
													/>
													{fileUrl && isEdit && (
														<div>
															<p>Existing File: {fileUrl} </p>
														</div>
													)}
												</FormGroup>
                                                <FormGroup>
													<Label
														htmlFor="message-text"
														className="col-form-label"
													>
														PDF Type :
													</Label>
													<Input
														className="form-control"
														type="select"
                                                        value={type}
														onChange={changeType}
													>
                                                        <option value='0' selected>PDFs material</option>
                                                        <option value='1'>EDF</option>
                                                        <option value='2'>Other</option>
                                                    </Input>
												</FormGroup>
											</Form>
										</ModalBody>
										<ModalFooter>
											<Button
												type="button"
												color="primary"
												onClick={onSave}
											>
												Save
											</Button>
											<Button
												type="button"
												color="secondary"
												onClick={onCloseModal}
											>
												Close
											</Button>
										</ModalFooter>
									</Modal>
								</div>
								<div className="clearfix"></div>
								<div id="basicScenario" className="product-physical">
									<Datatable
										multiSelectOption={false}
										multiSelectOptionShow={false}
										myData={data}
										pageSize={5}
										pagination={true}
										class="-striped -highlight"
                                        productTableType={6}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default PDFs_material;
