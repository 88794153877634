import axios from 'axios';
import { SERVER_URL } from '../config';

export const addVideo = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/addTrainingVideo`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const addFaq = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/addFaq`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const addNews = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/addNews`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const addPdf = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/addPdf`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getVideos = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/getTrainingVideos`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getVideoById = async (id) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/getTrainingVideoById`, {id});
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getFaqs = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/getFaqs`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getFaqById = async (id) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/getFaqById`, {id});
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getNews = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/getNews`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getNewsById = async (id) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/getNewsById`, {id});
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getPdfs = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/getPdfs`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getPdfById = async (id) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/getPdfById`, {id});
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const deleteVideo = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/deleteTrainingVideo`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const deleteFaq = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/deleteFaq`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const deleteNews = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/deleteNews`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const deletePdf = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/deletePdf`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const updateVideo = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/updateTrainingVideo`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const updateFaq = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/updateFaq`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const updateNews = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/updateNews`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const updatePdf = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/extras/updatePdf`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}