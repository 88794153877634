import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import Datatable from "../../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { getQBProductList, deleteSyncProductById } from "../../../actions";
import { SERVER_URL } from "../../../config";
import { Link } from "react-router-dom";
import { getProductsFromQB } from "../../../actions/quickbooks";

const Digital_pro_list = () => {
	const [data, setData] = useState([]);
	const [isImportLoading, SetIsImportLoading] = React.useState(false);

	const runQB = () => {
		SetIsImportLoading(true);
		getProductsFromQB().then(res => {
			if(res.success) {
				toast.success("Successfully Imported products from QB!");
				let temp = [];
				res.products.map((product) => {
					let imageUrl = SERVER_URL + '\\assets\\images\\dashboard\\product\\1.jpg';
					if(product.SyncProduct) {
						const image_list = JSON.parse(product.SyncProduct.image_list);
						if(image_list.length > 0) {
							imageUrl = SERVER_URL + '\\images\\products\\' + image_list[0];
						}
					}
					temp.push({
						ID: product.id,
						SKU: product.sku,
						Product: product.name,
						Description: product.description,
						"Cost Price": product.cost_price,
						"Sale Price": product.sale_price,
						"Qty Stock": product.qty_stock,
						image : <img alt="" src={imageUrl} style={{width:50, height:50}}/>,
						options: (
							<div style={{ display: "flex", justifyContent: "center"}}>
								<Link to={`/products/edit/${product.id}`}>
									<i
										className="fa fa-pencil-square-o"
										style={{
											width: 35,
											fontSize: 20,
											padding: 11,
											color: "#e4566e",
										}}
									></i>
								</Link>
								<UncontrolledDropdown style={{position: "unset"}} direction="down">
									<DropdownToggle tag="span" style={{ cursor: "pointer" }}>
									<i
										className="fa fa-ellipsis-v"
										style={{
										width: 35,
										fontSize: 20,
										padding: 11,
										color: "#e4566e",
										}}
									></i>
									</DropdownToggle>
									<DropdownMenu>
										<DropdownItem onClick={() => deleteProduct(product.id)}>Delete</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							</div>
						),
					})
				})

				setData(temp);
				SetIsImportLoading(false);
			}
		});
	};

	const deleteProduct = (productId) => {
		deleteSyncProductById(productId).then(res => {
			if(res.success) {
				getQBProductList().then(res => {
					let temp = [];
					res.products.map((product) => {
						let imageUrl = SERVER_URL + '\\assets\\images\\dashboard\\product\\1.jpg';
						if(product.SyncProduct) {
							const image_list = JSON.parse(product.SyncProduct.image_list);
							if(image_list.length > 0) {
								imageUrl = SERVER_URL + '\\images\\products\\' + image_list[0];
							}
						}
						temp.push({
							ID: product.id,
							SKU: product.sku,
							Product: product.name,
							Description: product.description,
							"Cost Price": product.cost_price,
							"Sale Price": product.sale_price,
							"Qty Stock": product.qty_stock,
							image : <img alt="" src={imageUrl} style={{width:50, height:50}}/>,
							options: (
								<div style={{ display: "flex", justifyContent: "center"}}>
									<Link to={`/products/edit/${product.id}`}>
										<i
											className="fa fa-pencil-square-o"
											style={{
												width: 35,
												fontSize: 20,
												padding: 11,
												color: "#e4566e",
											}}
										></i>
									</Link>
									<UncontrolledDropdown style={{position: "unset"}} direction="down">
										<DropdownToggle tag="span" style={{ cursor: "pointer" }}>
										<i
											className="fa fa-ellipsis-v"
											style={{
											width: 35,
											fontSize: 20,
											padding: 11,
											color: "#e4566e",
											}}
										></i>
										</DropdownToggle>
										<DropdownMenu>
											<DropdownItem onClick={() => deleteProduct(product.id)}>Delete</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</div>
							),
						})
					})
		
					setData(temp);
				});
				toast("The product has been deleted successfully.");
			}
		});
	}

	useEffect(() => {
		getQBProductList().then(res => {
			let temp = [];
			res.products.map((product) => {
				let imageUrl = SERVER_URL + '\\assets\\images\\dashboard\\product\\1.jpg';
				if(product.SyncProduct) {
					const image_list = JSON.parse(product.SyncProduct.image_list);
					if(image_list.length > 0) {
						imageUrl = SERVER_URL + '\\images\\products\\' + image_list[0];
					}
				}
				temp.push({
					ID: product.id,
					SKU: product.sku,
					Product: product.name,
					Description: product.description,
					"Cost Price": product.cost_price,
					"Sale Price": product.sale_price,
					"Qty Stock": product.qty_stock,
					image : <img alt="" src={imageUrl} style={{width:50, height:50}}/>,
					options: (
						<div style={{ display: "flex", justifyContent: "center"}}>
							<Link to={`/products/edit/${product.id}`}>
								<i
									className="fa fa-pencil-square-o"
									style={{
										width: 35,
										fontSize: 20,
										padding: 11,
										color: "#e4566e",
									}}
								></i>
							</Link>
							<UncontrolledDropdown style={{position: "unset"}} direction="down">
								<DropdownToggle tag="span" style={{ cursor: "pointer" }}>
								<i
									className="fa fa-ellipsis-v"
									style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: "#e4566e",
									}}
								></i>
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem onClick={() => deleteProduct(product.id)}>Delete</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</div>
					),
				})
			})

			setData(temp);
		})
	}, []);

	return (
		<Fragment>
			<Breadcrumb title="Product List" parent="Products" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid="true">
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5>Product Lists</h5>
								<div className="pull-right">
									<Button
										type="button"
										color="secondary"
										onClick={runQB}
									>
										{
											isImportLoading &&
											<Spinner size="sm" style={{marginRight: "0.5rem"}}>
												Loading...
											</Spinner>
										}
										Import from QuickBooks
									</Button>
								</div>
							</CardHeader>
							<CardBody>
								<div className="clearfix"></div>
								<div id="basicScenario" className="product-physical products-list">
									<Datatable
										multiSelectOptionShow={false}
										myData={data}
										pageSize={9}
										pagination={false}
										class="-striped -highlight"
										productTableType={2}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default Digital_pro_list;
