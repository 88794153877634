import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Container, Row, Col, Label, FormGroup, Input } from "reactstrap";
import { getTwilioSetting, setTwilioSetting } from '../../actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TwilioSetting = () => {
    const [accountSid, setAccountSid] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [number, setNumber] = useState("");

    const handleAccountSid = (e) => {
        setAccountSid(e.target.value);
    }

    const handleAuthToken = (e) => {
        setAuthToken(e.target.value);
    }

    const handleNumber = (e) => {
        setNumber(e.target.value);
    }

    const handleUpdate = () => {
        if(accountSid === "") {
            notify("Please input Account SID.");
            return;
        }
        if(authToken === "") {
            notify("Please input Auth Token.");
            return;
        }
        if(number === "") {
            notify("Please input Number.");
            return;
        }

        const data = {
            account_sid: accountSid,
            auth_token: authToken,
            number: number
        };
        setTwilioSetting(data).then((res) => {
            notify("Saved Successfully!", true);
        }).catch(err => {
            console.log(err)
        });
    }

    const notify = (text, success) => {
		const options = {
			position: "top-right",
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		};
		if (success) {
			toast.success(text, options);
		} else {
			toast.warn(text, options);
		}
	};

    useEffect(() => {
        getTwilioSetting().then(res => {
            if(res.success) {
                setAccountSid(res.data.account_sid);
                setAuthToken(res.data.auth_token);
                setNumber(res.data.number);
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])

    return (
        <Fragment>
            <Breadcrumb title="Twilio Settings" parent="Settings" />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Label className="col-form-label pt-0 color-red">
                                        <span>*</span> Account SID
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        required=""
                                        placeholder="Account SID"
                                        name="sid"
                                        value={accountSid}
                                        onChange={handleAccountSid}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="col-form-label pt-0 color-red">
                                        <span>*</span> Auth Token
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        required=""
                                        placeholder="Auth Token"
                                        name="token"
                                        value={authToken}
                                        onChange={handleAuthToken}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="col-form-label pt-0 color-red">
                                        <span>*</span> Number
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        required=""
                                        placeholder="Number"
                                        name="number"
                                        value={number}
                                        onChange={handleNumber}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="btn-popup pull-right" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '3rem' }}>
                            <div className="btn btn-secondary" onClick={handleUpdate}>
                                Save
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Container>
            <ToastContainer
				position="top-right"
				autoClose={4000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<ToastContainer />
        </Fragment>
    );
};

export default TwilioSetting;
