import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import Datatable from "../../common/datatable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import {
	addCategory,
	getCategories,
	getCategoryById,
	updateCategory,
	getCategoryListFromQB
} from '../../../actions';

const Digital_category = () => {
	const [data, setData] = React.useState([]);
	const [open, setOpen] = useState(false);
	const [name, setName] = React.useState("");
	const [nameError, setNameError] = React.useState(false);
	const [isEdit, setIsEdit] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(0);
	const [isImportLoading, SetIsImportLoading] = React.useState(false);

	const editCategoryModal = (id) => {
        getCategoryById(id).then(res => {
            if(res.success) {
                setIsEdit(true);
                setOpen(true);
                setName(res.category.name);
                setSelectedId(id);
            }
        });
    }

	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setName("");
		setOpen(false);
		setNameError(false);
		setIsEdit(false);
	};

	const changeName = (e) => {
		setName(e.target.value);
	};

	const runQB = () => {
		SetIsImportLoading(true);
		getCategoryListFromQB().then(res => {
			if(res.success) {
				SetIsImportLoading(false);
				toast.success("Successfully Imported categories from QB!");
				let temp = [];
				res.categories.map((category) => {
					temp.push({
						id: category.id,
						name: category.name,
						options: (
							<div style={{ display: "flex", justifyContent: "center"}}>
								<i
									className="fa fa-pencil-square-o"
									style={{
										width: 35,
										fontSize: 20,
										padding: 11,
										color: "#e4566e",
									}}
									onClick={(e) => editCategoryModal(category.id)}
								></i>
							</div>
						),
					});
				})
				setData(temp);
			}
		});
	};

	const onSave = () => {
		if(name === "") {
			setNameError(true);
			return;
		}

		if(isEdit) {
            const dataT = {
                id: selectedId,
                name: name,
            };
            updateCategory(dataT).then(res => {
                if(res.success) {
                    toast.success("Successfully Updated!");
                    let temp = [...data];
                    temp = temp.map(category => {
                        if (category.id === selectedId) {
                            return {
                                ...category,
                                name: name,
                            };
                        } else {
                            return category;
                        }
                    });
                    setData(temp);
                    onCloseModal();
                } 
            });
        } else {
            const dataT = {
				name: name
			};
			addCategory(dataT).then(res => {
				let temp = [...data];
				temp.push({
					id: res.id,
					name: name,
					options: (
                        <div style={{ display: "flex", justifyContent: "center"}}>
                            <i
                                className="fa fa-pencil-square-o"
                                style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                }}
                                onClick={(e) => editCategoryModal(res.id)}
                            ></i>
                        </div>
                    ),
				});
				setData(temp);
				toast.success("Successfully Added!");
				onCloseModal();
			});
        }
	};

	React.useEffect(() => {
		getCategories().then(res => {
			let temp = [];
			res.categories.forEach(category => {
				temp.push({
					id: category.id,
					name: category.name,
					options: (
                        <div style={{ display: "flex", justifyContent: "center"}}>
                            <i
                                className="fa fa-pencil-square-o"
                                style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                }}
                                onClick={(e) => editCategoryModal(category.id)}
                            ></i>
                        </div>
                    ),
				});
			});
			setData(temp);
		});
	}, []);

	return (
		<Fragment>
			<Breadcrumb title="Category" parent="Products" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5>Category</h5>
							</CardHeader>
							<CardBody>
								<div className="btn-popup pull-right">
									<Button
										type="button"
										color="secondary"
										className="category-import-button"
										onClick={runQB}
									>
										{
											isImportLoading &&
											<Spinner size="sm" style={{marginRight: "0.5rem"}}>
												Loading...
											</Spinner>
										}
										Import from QuickBooks
									</Button>
									<Button
										type="button"
										color="secondary"
										onClick={onOpenModal}
										data-toggle="modal"
										data-original-title="test"
										data-target="#exampleModal"
									>
										Add Category
									</Button>
									<Modal isOpen={open} toggle={onCloseModal}>
										<ModalHeader toggle={onCloseModal}>
											<h5
												className="modal-title f-w-600"
												id="exampleModalLabel2"
											>
												{ isEdit ? "Edit Category" : "Add Category" }
											</h5>
										</ModalHeader>
										<ModalBody>
											<Form>
												<FormGroup>
													<Label
														htmlFor="recipient-name"
														className="col-form-label"
													>
														Name :
													</Label>
													<Input type="text" className={nameError ? 'form-control input-error' : 'form-control'} value={name}
														onChange={changeName} />
												</FormGroup>
											</Form>
										</ModalBody>
										<ModalFooter>
											<Button
												type="button"
												color="primary"
												onClick={onSave}
											>
												Save
											</Button>
											<Button
												type="button"
												color="secondary"
												onClick={onCloseModal}
											>
												Close
											</Button>
										</ModalFooter>
									</Modal>
								</div>
								<div className="clearfix"></div>
								<div id="basicScenario" className="product-physical">
									<Datatable
										multiSelectOption={false}
										multiSelectOptionShow={false}
										myData={data}
										pageSize={5}
										pagination={true}
										class="-striped -highlight"
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default Digital_category;
