import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { getAllAdminUsers, deleteAdminUser } from '../../actions';

const ListAdminUser = () => {
	const userTypeList = ["Super Admin", "Admin", "Office", "Warehouse ADM", "Warehouse"];
	const [data, setData] = useState([]);
	
	const removeAdminUser = (id) => {
		deleteAdminUser({id}).then(res => {
			if(res.success) {
				let temp = [];
				res.admins.forEach((user, index) => {
					temp.push({
						id: user.id,
						f_name: user.first_name,
						l_name: user.last_name,
						email: user.email,
						type: userTypeList[user.role],
						options : <div style={{ display: "flex", justifyContent: "center" }}>
							<Link
								to={`/admin_users/edit/${user.id}`}
							>
								<i
									className="fa fa-pencil-square-o"
									style={{
										width: 35,
										fontSize: 20,
										padding: 11,
										color: "#e4566e",
									}}
								></i>
							</Link>
							<UncontrolledDropdown style={{position: "unset"}} direction="down">
                                <DropdownToggle tag="span" style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-ellipsis-v"
                                    style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                    }}
                                ></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => removeAdminUser(user.id)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
						</div>
					});
				});
				setData(temp);
				toast.success("Successfully Deleted!");
			}
		});
	};

	useEffect(() => {
		getAllAdminUsers().then(res => {
			if(res.success) {
				let temp = [];
				res.users.forEach((user, index) => {
					temp.push({
						id: user.id,
						f_name: user.first_name,
						l_name: user.last_name,
						email: user.email,
						type: userTypeList[user.role],
						options : <div style={{ display: "flex", justifyContent: "center" }}>
							<Link
								to={`/admin_users/edit/${user.id}`}
							>
								<i
									className="fa fa-pencil-square-o"
									style={{
										width: 35,
										fontSize: 20,
										padding: 11,
										color: "#e4566e",
									}}
								></i>
							</Link>
							<UncontrolledDropdown style={{position: "unset"}} direction="down">
                                <DropdownToggle tag="span" style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-ellipsis-v"
                                    style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                    }}
                                ></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => removeAdminUser(user.id)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
						</div>
					});
				});
				setData(temp);
			}
		});
	}, []);

	return (
		<Fragment>
			<Breadcrumb title="Admin User List" parent="Admin Users" />
			<Container fluid={true}>
				<Card>
					<CardHeader>
						<h5>Admin User Details</h5>
					</CardHeader>
					<CardBody>
						<div className="btn-popup pull-right">
							<Link to="/admin_users/create-admin-user" className="btn btn-secondary">
								Create Admin User
							</Link>
						</div>
						<div className="clearfix"></div>
						<div
							id="batchDelete"
							className="category-table user-list order-table coupon-list-delete"
						>
							<Datatable
								multiSelectOption={false}
								multiSelectOptionShow={false}
								myData={data}
								pageSize={10}
								pagination={true}
								class="-striped -highlight"
								adminUserTable={true}
							/>
						</div>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default ListAdminUser;
