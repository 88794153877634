import { applyMiddleware, createStore, compose } from 'redux';
import rootReducer from '../reducers'; // Your root reducer file

// Manually create a thunk-like middleware
const manualThunkMiddleware = ({ dispatch, getState }) => next => action => {
  if (typeof action === 'function') {
    return action(dispatch, getState);
  }
  return next(action);
};

// Apply the manually created middleware along with other middlewares, if any
const storeEnhancers = compose(
  applyMiddleware(manualThunkMiddleware)
  // Add other middlewares here if needed
);

// Create the Redux store with the combined enhancers
const store = createStore(rootReducer, storeEnhancers);
export default store;