import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { 
	Button, Card, CardBody, CardHeader,
	Col, Container, FormGroup, Input, Label, Row, Form,
	Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import DatePicker from "react-datepicker";
import { getSyncProductById, updateSyncProduct, getCategories, getQBProductList, deleteSyncProductById } from "../../../actions";
import BeautyEditor from "../../common/BeautyEditor";
import MyDropzone from "../../common/dropzone";
import Datatable from "../../common/datatable";
import loadingIcon from '../../../assets/images/loading-icon.svg'
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import {
    Plus
} from 'react-feather';
import { toast } from 'react-toastify';
import Multiselect from 'multiselect-react-dropdown';
import { SERVER_URL } from "../../../config";

const Digital_edit_pro = () => {
	const navigate = useNavigate();
	const params = useParams();

	const [productList, setProductList] = useState([]);
	const [note, setNote] = useState('');
	const [category_list, setCategoryList] = useState([]);
	const [uploadedImages, setUploadedImages] = useState([]);
	const [product_name, setProductName] = React.useState("");
	const [product_category_qb_id, setProductCategoryQBId] = React.useState(0);
	const [product_sale_price, setProductSalePrice] = React.useState(0);
	const [product_cost_price, setProductCostPrice] = React.useState(0);
	const [product_sku, setProductSKU] = React.useState("");
	const [product_description, setProductDescription] = React.useState("");
	const [product_qty, setProductQty] = React.useState(0);
	const [required_products, setRequiredProducts] = useState([]);
	const [selected_required_products, setSelectedRequiredProducts] = useState([]);
	const [loading, setLoading] = React.useState(false);
	const [image_list, setImageList] = useState([]);
	const [initialFiles, setInitialFiles] = useState([]);
	const [productComponentData, setProductComponentData] = useState([]);
	const [isShowProductComponentModal, setIsShowProductComponentModal] = useState(false);
	const [productComponentName, setProductComponentName] = useState("");
	const [productComponentQTY, setProductComponentQTY] = useState(0);
	const [productComponentBarcode, setProductComponentBarcode] = useState("");
	const [productComponentWeight, setProductComponentWeight] = useState(0);
	const [productComponentType, setProductComponentType] = useState("Box");
	const [productComponentLocation, setProductComponentLocation] = useState("");
	const [product_stock_unit, setProductStockUnit] = useState("EA");
	const [product_qty_coming_date, setProductQtyComingDate] = useState("");
	const [product_qty_coming, setProductQtyComing] = useState(0);
	const [product_status, setProductStatus] = useState("Active On");
	const [product_package_option, setProductPackageOption] = useState("SHARE");

	const [productComponentNameError, setProductComponentNameError] = useState(false);
	const [productComponentQTYError, setProductComponentQTYError] = useState(false);
	const [productComponentBarcodeError, setProductComponentBarcodeError] = useState(false);
	const [productComponentWeightError, setProductComponentWeightError] = useState(false);
	const [productComponentLocationError, setProductComponentLocationError] = useState(false);

	const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result);
			}
			fileReader.onerror = (error) => {
				reject(error);
			}
		})
	}
	const onChangeProductName = (e) => {
		setProductName(e.target.value)
	}
	const onChangeProductSalePrice = (e) => {
		setProductSalePrice(e.target.value)
	}
	const onChangeProductCostPrice = (e) => {
		setProductCostPrice(e.target.value)
	}
	const onChangeProductSKU = (e) => {
		setProductSKU(e.target.value)
	}
	const onChangeProductDescription = (e) => {
		setProductDescription(e.target.value);
	}
	const onChangeProductQty = (e) => {
		setProductQty(e.target.value);
	}
	const onChangeNote = (e) => {
		setNote(e)
	}
	const onChangeSelectCategory = (e) => {
		setProductCategoryQBId(parseInt(e.target.value));
	}

	const showProductComponentModal = () => {
		setIsShowProductComponentModal(true);
	}
	const closeProductComponentModal = () => {
		setProductComponentName("");
		setProductComponentQTY(0);
		setProductComponentBarcode("");
		setProductComponentWeight(0);
		setProductComponentType("Box");
		setProductComponentLocation("");
		setProductComponentNameError(false);
		setProductComponentQTYError(false);
		setProductComponentBarcodeError(false);
		setProductComponentWeightError(false);
		setProductComponentLocationError(false);
		setIsShowProductComponentModal(false);
	}
	const onChangeProductComponentName = (e) => {
		setProductComponentName(e.target.value);
	};
	const onChangeProductComponentQTY = (e) => {
		setProductComponentQTY(e.target.value);
	};
	const onChangeProductComponentBarcode = (e) => {
		setProductComponentBarcode(e.target.value);
	};
	const onChangeProductComponentWeight = (e) => {
		setProductComponentWeight(e.target.value);
	};
	const onChangeProductComponentType = (e) => {
		setProductComponentType(e.target.value);
	};
	const onChangeProductComponentLocation = (e) => {
		setProductComponentLocation(e.target.value);
	};
	const onSelectRequiredProducts = (e) => {
		const ids = e.map(item => item.qb_id);
		setRequiredProducts(ids);
	};
	const onChangeProductStockUnit = (e) => {
		setProductStockUnit(e.target.value);
	}
	const onChangeProductQtyComingDate = (date) => {
		setProductQtyComingDate(date);
	};
	const onChangeProductQtyComing = (e) => {
		setProductQtyComing(e.target.value);
	};
	const onChangeProductStatus = (e) => {
		setProductStatus(e.target.value);
	};
	const onChangeProductPackageOption = (e) => {
		setProductPackageOption(e.target.value);
	};

	const onSave = async () => {
		try {
			if(product_name === "" || product_sku === "" || product_description === "" 
				|| product_sale_price === "" || product_cost_price === "" || product_qty === "") 
			{
				toast("Please fill all required fields.");
				return;
			}
			
			setLoading(true);
			if(product_status === "Inactive") {
				deleteSyncProductById(params.id).then(res => {
					if(res.success) {
						toast("The product has been updated successfully.");
						navigate('/products/product-list');
					} else {
						toast.error("Server Error.");
					}
					setLoading(false);
				});
			} else {
				let data = {
					id: params.id,
					name: product_name,
					category_id: product_category_qb_id,
					sku: product_sku,
					description: product_description,
					sale_price: parseFloat(product_sale_price),
					cost_price: parseFloat(product_cost_price),
					qty: parseInt(product_qty),
					image_list: image_list,
					components: JSON.stringify(productComponentData),
					required_products: JSON.stringify(required_products),
					note: note,
					stock_unit: product_stock_unit,
					qty_coming_date: product_qty_coming_date,
					qty_coming: product_qty_coming,
					status: product_status,
					package_option: product_package_option
				}
				updateSyncProduct(data).then(res => {
					if(res.success) {
						setLoading(false);
						toast("The product has been updated successfully.");
						navigate('/products/product-list');
					}
				});
			}
		} catch (err) {}
	}

	const onSaveProductComponent = async () => {
		try {
			if(productComponentName === '' || productComponentQTY === '' || productComponentWeight === '' || productComponentLocation === '') {
				if(productComponentName === '') {
					setProductComponentNameError(true);
				} else {
					setProductComponentNameError(false);
				}
				if(productComponentQTY === '') {
					setProductComponentQTYError(true);
				} else {
					setProductComponentQTYError(false);
				}
				if(productComponentBarcode === '') {
					setProductComponentBarcodeError(true);
				} else {
					setProductComponentBarcodeError(false);
				}
				if(productComponentWeight === '') {
					setProductComponentWeightError(true);
				} else {
					setProductComponentWeightError(false);
				}
				if(productComponentLocation === '') {
					setProductComponentLocationError(true);
				} else {
					setProductComponentLocationError(false);
				}
				return;
			}
			let data = {
				Name: productComponentName,
				QTY: productComponentQTY,
				Barcode: productComponentBarcode,
				Weight: productComponentWeight,
				Type: productComponentType,
				Location: productComponentLocation,
			}
			let temp = [...productComponentData];
			temp.push(data);
			setProductComponentData(temp);
			closeProductComponentModal();
		} catch (err) {}
	}

	useEffect(() => {
		let temp = [];
		uploadedImages.forEach(async (image) =>{
			const base64 = await convertBase64(image.file);
			temp.push(base64);
		});
		setImageList(temp);
	}, [uploadedImages]);

	useEffect(() => {
		getCategories().then(res => {
			setCategoryList(res.categories);
		});
		getQBProductList().then(res => {
			if(res.success) {
				setProductList(res.products);
				const products = res.products;
				if (params?.id) {
					getSyncProductById(params.id).then(async (res) => {
						if(res.success) {
							setProductName(res.product.name);
							setProductSKU(res.product.sku);
							setProductCategoryQBId(res.product.category_id);
							setProductDescription(res.product.description);
							setProductSalePrice(res.product.sale_price);
							setProductCostPrice(res.product.cost_price);
							setProductQty(res.product.qty_stock);
							
							if(res.product.SyncProduct) {
								setProductComponentData(JSON.parse(res.product.SyncProduct.product_components));
								if(JSON.parse(res.product.SyncProduct.required_products).length > 0) {
									const filteredArray = products.filter(obj => JSON.parse(res.product.SyncProduct.required_products).includes(obj.qb_id));
									setSelectedRequiredProducts(filteredArray);
									setRequiredProducts(JSON.parse(res.product.SyncProduct.required_products));
								}
								if(JSON.parse(res.product.SyncProduct.image_list).length > 0) {
									const img_list = JSON.parse(res.product.SyncProduct.image_list);
									let files = [];
									await Promise.all(img_list.map(async (img) => {
										const imageUrl = SERVER_URL + '/images/products/' + img;
										const res = await fetch(imageUrl);
										const buf = await res.arrayBuffer();
										const file = new File([buf], img, { type: 'image/' + img.split('.')[1] });
										files.push(file);
									}));
									setInitialFiles(files);
								}
								setNote(res.product.SyncProduct.note);
								setProductStockUnit(res.product.SyncProduct.stock_unit);
								setProductQtyComingDate(res.product.SyncProduct.qty_coming_date === null ? "" : new Date(res.product.SyncProduct.qty_coming_date));
								setProductQtyComing(res.product.SyncProduct.qty_coming);
								setProductStatus(res.product.SyncProduct.status);
								setProductPackageOption(res.product.SyncProduct.package_option);
							}
						}
					});
				}
			}
		});
	}, [params.id]);

	return (
		<Fragment>
			<Breadcrumb title="Edit Product" parent="Products" />
			<Container fluid={true}>
				<Row className="product-adding">
					<Col xl="6">
						<Card>
							<CardHeader>
								<h5>General</h5>
							</CardHeader>
							<CardBody>
								<div className="digital-add needs-validation">
									<FormGroup>
										<Label className="col-form-label pt-0">
											<span>*</span> Product Name
										</Label>
										<Input
											className="form-control"
											id="validationCustom01"
											type="text"
											required=""
											value={product_name}
											onChange={onChangeProductName}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">
											Categories
										</Label>
										<select className="form-select" required=""
											onChange={onChangeSelectCategory}
											value={product_category_qb_id}
										>
											<option key={0} value={0}>None</option>
											{
												category_list.map((category, index) => (
													<option key={category.id} value={category.qb_id}>{category.name}</option>
												))
											}
										</select>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label pt-0">
											<span>*</span> SKU
										</Label>
										<Input
											className="form-control"
											type="text"
											required=""
											value={product_sku}
											onChange={onChangeProductSKU}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label pt-0">
											<span>*</span> Description
										</Label>
										<textarea rows="4" value={product_description} onChange={onChangeProductDescription}></textarea>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">
											<span>*</span> Product Sale Price
										</Label>
										<Input
											className="form-control"
											type="number"
											required=""
											value={product_sale_price}
											onChange={onChangeProductSalePrice}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">
											<span>*</span> Product Cost Price
										</Label>
										<Input
											className="form-control"
											type="number"
											required=""
											value={product_cost_price}
											onChange={onChangeProductCostPrice}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">
											<span>*</span> Qty on Hand
										</Label>
										<Input
											className="form-control"
											type="number"
											required=""
											value={product_qty}
											onChange={onChangeProductQty}
										/>
									</FormGroup>
									<div className="product-physical products-list">
										<Label className="col-form-label">
											Product Component
											<Button
												type="button"
												color="secondary"
												style={{marginLeft: "0.5rem", padding: "0 0.25rem"}}
												onClick={showProductComponentModal}
											>
												<Plus />
											</Button>
										</Label>
										<Datatable
											myData={productComponentData}
											pagination={false}
											pageSize={20}
											class="-striped -highlight"
											productTableType={7}
										/>
									</div>
									<FormGroup>
										<Label className="col-form-label">
											<span>*</span> Stock Unit
										</Label>
										<select className="form-select" required=""
											onChange={onChangeProductStockUnit}
											value={product_stock_unit}
										>
											<option value="EA">EA</option>
											<option value="KIT">KIT</option>
											<option value="FT">FT</option>
										</select>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">
											<span>*</span> Qty Coming Date
										</Label>
										<DatePicker
											className="form-control mb-2"
											selected={product_qty_coming_date}
											onChange={onChangeProductQtyComingDate}
											minDate={tomorrow}
										/>
										<Input
											className="form-control"
											type="number"
											required=""
											value={product_qty_coming}
											onChange={onChangeProductQtyComing}
											placeholder="Comming Qty"
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">
											<span>*</span> Status
										</Label>
										<select className="form-select" required=""
											onChange={onChangeProductStatus}
											value={product_status}
										>
											<option value="Active On">Active On</option>
											<option value="Active Off">Active Off</option>
											<option value="Special Order">Special Order</option>
											<option value="Back Order">Back Order</option>
											<option value="Inactive">Inactive</option>
										</select>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">
											<span>*</span> Package Option
										</Label>
										<select className="form-select" required=""
											onChange={onChangeProductPackageOption}
											value={product_package_option}
										>
											<option value="SHARE">SHARE</option>
											<option value="SOLO">SOLO</option>
										</select>
									</FormGroup>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col xl="6">
						<Card>
							<CardHeader>
								<h5>Product Images</h5>
							</CardHeader>
							<CardBody>
								<div className="digital-add needs-validation">
									<MyDropzone uploadedImages={uploadedImages} setUploadedImages={setUploadedImages} initialFiles={initialFiles} />
								</div>
							</CardBody>
						</Card>
						<Card>
							<CardHeader>
								<h5>Required Product</h5>
							</CardHeader>
							<CardBody>
								<Multiselect
									displayValue="name"
									onKeyPressFn={function noRefCheck(){}}
									onRemove={onSelectRequiredProducts}
									onSearch={function noRefCheck(){}}
									onSelect={onSelectRequiredProducts}
									selectedValues={selected_required_products}
									options={productList}
								/>
							</CardBody>
						</Card>
						<Card>
							<CardHeader>
								<h5>Note</h5>
							</CardHeader>
							<CardBody>
								<div className="digital-add needs-validation">
									<FormGroup className=" mb-0">
										<div className="description-sm">
											<BeautyEditor
												name="Note"
												content={note}
												onChange={(note) => onChangeNote(note)}
												id={3}
											/>
										</div>
									</FormGroup>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Button
						type="button"
						color="secondary"
						disabled={loading}
						onClick={() => onSave()}
					>
						{loading && <img src={loadingIcon} width='1.5%' />} Update Product
					</Button>
				</Row>
			</Container>
			<Modal isOpen={isShowProductComponentModal} toggle={closeProductComponentModal}>
				<ModalHeader toggle={closeProductComponentModal}>
					<h5
						className="modal-title f-w-600"
					>
						Add Product Component
					</h5>
				</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup>
							<Label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Name :
							</Label>
							<Input type="text" className={productComponentNameError ? 'form-control input-error' : 'form-control'} value={productComponentName}
								onChange={onChangeProductComponentName} />
						</FormGroup>
						<FormGroup>
							<Label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								QTY :
							</Label>
							<Input type="number" className={productComponentQTYError ? 'form-control input-error' : 'form-control'} value={productComponentQTY}
								onChange={onChangeProductComponentQTY} />
						</FormGroup>
						<FormGroup>
							<Label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Barcode :
							</Label>
							<Input type="text" className={productComponentBarcodeError ? 'form-control input-error' : 'form-control'} value={productComponentBarcode}
								onChange={onChangeProductComponentBarcode} />
						</FormGroup>
						<FormGroup>
							<Label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Weight :
							</Label>
							<Input type="number" className={productComponentWeightError ? 'form-control input-error' : 'form-control'} value={productComponentWeight}
								onChange={onChangeProductComponentWeight} />
						</FormGroup>
						<FormGroup>
							<Label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Type :
							</Label>
							<select className="form-select" required=""
								onChange={onChangeProductComponentType}
								value={productComponentType}
							>
								<option value="Box">Box</option>
								<option value="Bag">Bag</option>
							</select>
						</FormGroup>
						<FormGroup>
							<Label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Location :
							</Label>
							<Input type="text" className={productComponentLocationError ? 'form-control input-error' : 'form-control'} value={productComponentLocation}
								onChange={onChangeProductComponentLocation} />
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button
						type="button"
						color="primary"
						onClick={onSaveProductComponent}
					>
						Save
					</Button>
					<Button
						type="button"
						color="secondary"
						onClick={closeProductComponentModal}
					>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</Fragment>
	);
};

export default Digital_edit_pro;
