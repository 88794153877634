import React, { Fragment, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import {
	addFaq,
	getFaqs,
    getFaqById,
    updateFaq,
    deleteFaq
} from '../../actions';

const Faq = () => {
    const [data, setData] = React.useState([]);
	const [open, setOpen] = useState(false);
	const [title, setTitle] = React.useState("");
	const [titleError, setTitleError] = React.useState(false);
    const [content, setContent] = React.useState("");
	const [isEdit, setIsEdit] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(0);

    const editFAQModal = (id) => {
        getFaqById(id).then(res => {
            if(res.success) {
                setIsEdit(true);
                setOpen(true);
                setTitle(res.faq.title);
                setContent(res.faq.content);
                setSelectedId(id);
            }
        });
    }

	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setTitle("");
        setContent("");
		setOpen(false);
		setTitleError(false);
        setIsEdit(false);
	};

	const changeTitle = (e) => {
		setTitle(e.target.value);
	};

    const changeContent = (e) => {
        setContent(e.target.value);
    }

    const removeFAQ = (id) => {
        deleteFaq({id}).then(res => {
            if(res.success) {
                let temp = [];
                res.faqs.forEach(faq => {
                    temp.push({
                        id: faq.id,
                        title: faq.title,
                        content: faq.content,
                        options: (
                            <div style={{ display: "flex", justifyContent: "center"}}>
                                <i
                                    className="fa fa-pencil-square-o"
                                    style={{
                                        width: 35,
                                        fontSize: 20,
                                        padding: 11,
                                        color: "#e4566e",
                                    }}
                                    onClick={(e) => editFAQModal(faq.id)}
                                ></i>
                                <UncontrolledDropdown style={{position: "unset"}} direction="down">
                                    <DropdownToggle tag="span" style={{ cursor: "pointer" }}>
                                    <i
                                        className="fa fa-ellipsis-v"
                                        style={{
                                        width: 35,
                                        fontSize: 20,
                                        padding: 11,
                                        color: "#e4566e",
                                        }}
                                    ></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => removeFAQ(faq.id)}>Delete</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        ),
                    });
                });
                setData(temp);
                toast.success("Successfully Deleted!");
            }
        });
    }

	const onSave = () => {
		if(title === "") {
			setTitleError(true);
			return;
		}

        if(isEdit) {
            const dataT = {
                id: selectedId,
                title: title,
                content: content
            };
            updateFaq(dataT).then(res => {
                if(res.success) {
                    toast.success("Successfully Updated!");
                    let temp = [...data];
                    temp = temp.map(faq => {
                        if (faq.id === selectedId) {
                            return {
                                ...faq,
                                title: title,
                                content: content
                            };
                        } else {
                            return faq;
                        }
                    });
                    setData(temp);
                    onCloseModal();
                } 
            });
        } else {
            const dataT = {
                title: title,
                content: content
            };
            addFaq(dataT).then(res => {
                let temp = [...data];
                temp.push({
                    id: res.id,
                    title: title,
                    content: content,
                    options: (
                        <div style={{ display: "flex", justifyContent: "center"}}>
                            <i
                                className="fa fa-pencil-square-o"
                                style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                }}
                                onClick={(e) => editFAQModal(res.id)}
                            ></i>
                            <UncontrolledDropdown style={{position: "unset"}} direction="down">
                                <DropdownToggle tag="span" style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-ellipsis-v"
                                    style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                    }}
                                ></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => removeFAQ(res.id)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    ),
                });
                setData(temp);
                toast.success("Successfully Added!");
                onCloseModal();
            });
        }
	};

	React.useEffect(() => {
		getFaqs().then(res => {
			let temp = [];
			res.faqs.forEach(faq => {
				temp.push({
					id: faq.id,
					title: faq.title,
					content: faq.content,
                    options: (
                        <div style={{ display: "flex", justifyContent: "center"}}>
                            <i
                                className="fa fa-pencil-square-o"
                                style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                }}
                                onClick={(e) => editFAQModal(faq.id)}
                            ></i>
                            <UncontrolledDropdown style={{position: "unset"}} direction="down">
                                <DropdownToggle tag="span" style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-ellipsis-v"
                                    style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                    }}
                                ></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => removeFAQ(faq.id)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    ),
				});
			});
			setData(temp);
		});
	}, []);

    return (
        <Fragment>
            <Breadcrumb title="FAQ" parent="Extras" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>FAQ</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="btn-popup pull-right">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={onOpenModal}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"
                                    >
                                        Add faq
                                    </Button>
                                    <Modal isOpen={open} toggle={onCloseModal}>
                                        <ModalHeader toggle={onCloseModal}>
                                            <h5
                                                className="modal-title f-w-600"
                                                id="exampleModalLabel2"
                                            >
                                                { isEdit ? "Edit FAQ" : "Add FAQ" }
                                            </h5>
                                        </ModalHeader>
                                        <ModalBody>
                                            <Form>
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="recipient-name"
                                                        className="col-form-label"
                                                    >
                                                        Title :
                                                    </Label>
                                                    <Input type="text" className={titleError ? 'form-control input-error' : 'form-control'} value={title}
                                                        onChange={changeTitle} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="message-text"
                                                        className="col-form-label"
                                                    >
                                                        Content :
                                                    </Label>
                                                    <textarea rows={5} type="text" className={titleError ? 'form-control input-error' : 'form-control'} value={content}
                                                        onChange={changeContent} />
                                                </FormGroup>
                                            </Form>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                type="button"
                                                color="primary"
                                                onClick={onSave}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                type="button"
                                                color="secondary"
                                                onClick={onCloseModal}
                                            >
                                                Close
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    <Datatable
                                        multiSelectOption={false}
                                        multiSelectOptionShow={false}
                                        myData={data}
                                        pageSize={5}
                                        pagination={true}
                                        class="-striped -highlight"
                                        productTableType={4}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default Faq;