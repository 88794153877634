import React, {useEffect, useState} from 'react'
import Dropzone from 'react-dropzone-uploader'


const MyUploader = ({ uploadedImages, setUploadedImages, initialFiles = [] }) => {

  const [display, setDisplay] = useState(false);
  const [files, setFiles] = useState([]);
  const [callCount, setCallCount] = useState(0);

  useEffect(() => {
  }, [display])

  // specify upload params and url for your files
  const getUploadParams = () => {
    setDisplay(!display)
    return { url: "https://httpbin.org/post"};
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const uploadedImage = { meta, file };
      setUploadedImages([...uploadedImages, uploadedImage]);
    } else if(status === 'removed') {
      const newFiles = uploadedImages.filter(f => f.file !== file);
      setUploadedImages(newFiles);
    }
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    console.log("HandleSubmit",files.map(f => f.meta));
    allFiles.forEach(f => f.remove());
  };

  useEffect(() => {
    if(callCount === 0 && initialFiles.length > 0) {
      setCallCount(prevCount => prevCount + 1);
      setFiles(initialFiles);
    }
  }, [initialFiles]);

  return (
    <div className="dropzone">
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      initialFiles={files}
      styles={{ dropzone: { minHeight: 100 } }}
    />
    </div>
    
  )
}

export default MyUploader