import axios from 'axios';
import { SERVER_URL } from '../config';

export const getShippingSetting = async () => {
	try {
		let res = await axios.get(`${SERVER_URL}/api/setting/getShipping`);
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const setShippingSetting = async (data) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/setting/setShipping`, data);
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const getTwilioSetting = async () => {
	try {
		let res = await axios.get(`${SERVER_URL}/api/setting/getTwilio`);
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const setTwilioSetting = async (data) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/setting/setTwilio`, data);
		return res.data;
	} catch(err) {
		console.log(err)
	}
}