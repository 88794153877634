import axios from 'axios';
import { SERVER_URL } from '../config';

export const accessQB = async () => {
	try {
		let res = await axios.get(`${SERVER_URL}/api/quickbooks/qbConnector`);
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const getProductsFromQB = async () => {
	try {
		let res = await axios.get(`${SERVER_URL}/api/quickbooks/getProductsFromQB`);
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const getQBProductList = async () => {
	try {
		let res = await axios.get(`${SERVER_URL}/api/quickbooks/getProductList`) ;
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const getCategoryListFromQB = async () => {
	try {
		let res = await axios.get(`${SERVER_URL}/api/quickbooks/getCategoryListFromQB`) ;
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const getQBSetting = async () => {
	try {
		let res = await axios.get(`${SERVER_URL}/api/quickbooks/getSetting`) ;
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const setQBSetting = async (data) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/quickbooks/setSetting`, data) ;
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const testQBConnection = async (data) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/quickbooks/testConnection`, data) ;
		return res.data;
	} catch(err) {
		console.log(err)
	}
}